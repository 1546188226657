<template>
  <div class="br" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select
            v-model="searchForm.state"
            clearable
            v-if="permissoin.includes('query')"
            size="mini"
          >
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-if="permissoin.includes('query')"
            type="text"
            clearable
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="
              modal = 1;
              modalState = 0;
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="作废类型"> 
              <template slot-scope="scope">
                <span v-if="scope.row.mainType == 1" >发票作废</span>
              </template>
            </el-table-column>
            
            <el-table-column prop="userName" label="申请人"> </el-table-column>
            <el-table-column prop="createTime" label="申请时间">
            </el-table-column>
            <el-table-column prop="invoiceNum" label="票据编号" width="220px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="260px" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="amount" label="申请金额">
              <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.amount,2)}}</span>
                    </template>
            </el-table-column>
            <!-- <el-table-column prop="createTime" label="申请类型">
              <template slot-scope="scope">
                <span v-if="scope.row.mainType == 1">开票记录</span>
              </template>
            </el-table-column> -->
            <el-table-column label="开票状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 1" class="colorred">已作废</span>
                <span v-if="scope.row.state == 2" class="colororange"
                  >待审核</span
                >
                <span v-if="scope.row.state == 3" class="colorred">驳回</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="permissoin.includes('query')"
                      icon="el-icon-search"
                      :command="{ num: 2, data: scope.row }"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="
                        scope.row.state == 2 && permissoin.includes('examine')
                      "
                      :command="{ num: 4, data: scope.row }"
                      >审核</el-dropdown-item
                    >
                    <!-- <el-dropdown-item
                    icon="el-icon-delete" 
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增</span>
          <span v-else>详情</span>
          <span
            @click="
              modal = 0;
              form = {};
              fileList = [];
              billForm = {};
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <p>
            <span class="spaa"><span class="star">* </span>申请类型</span>
            <el-select
              @change="change"
              v-model="form.mainType"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option2"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <p v-show="modalState == 0">
            <span class="spaa"><span class="star">* </span>作废记录</span>
            <el-select
              v-model="form.mainId"
              clearable
              filterable
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option5"
                :key="item.id"
                :label="item.invoiceNum"
                :value="item.id"
              >
              <span
                  style="margin-right: 10px; color: #333; font-size: 12px"
                  >{{ item.invoiceNum }} · ￥{{ item.amount }}</span>
                <span
                  style="margin-right: 10px; color: #333; font-size: 12px"
                  >抬头:{{ item.rise }}</span>
                <span style="color: #333; font-size: 12px"
                  >项目:{{ item.name}}</span>
              </el-option>
            </el-select>
          </p>
          <div v-show="modalState != 0">
            <p>
              <span class="spaa"><span class="star">* </span>申请名称</span>
              <el-input disabled v-model="billForm.name" size="mini"></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>申请时间</span>
              <el-input
                disabled
                v-model="billForm.createTime"
                size="mini"
              ></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>申请人</span>
              <el-input
                disabled
                v-model="billForm.userName"
                size="mini"
              ></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>出票单位</span>
              <el-input
                disabled
                v-model="billForm.company"
                size="mini"
              ></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>发票详情</span>
              <span v-if="billForm.invoiceType == 1"
                >普通发票（{{ billForm.taxRate }}%）</span
              >
              <span v-if="billForm.invoiceType == 2"
                >增值税专用发票（{{ billForm.taxRate }}%）</span
              >
              <span style="margin-left: 20px" v-if="billForm.amount"
                >{{ billForm.amount }}元</span
              >
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>票据编号</span>
              <el-input
                disabled
                v-model="billForm.invoiceNum"
                size="mini"
              ></el-input>
            </p>
          </div>

          <p>
            <span class="spaa"><span class="star">* </span>作废原因</span>
            <el-input type="text" size="mini" v-model="form.reason"></el-input>
          </p>
          <p v-show="form.state == 2 && modalState == 4" style="display: flex">
            <span class="spaa"><span class="star">* </span>作废文件</span>
            <el-upload
              class="upload-demo"
              :action="axiosUrl + '/admin/l/file/uploadPicture'"
              :on-success="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </p>
          <p v-show="form.state == 1">
            <span class="spa" style="min-width:75px">作废文件</span>
            <el-image
              v-for="item in srcList"
              :key="item"
              style="width: 100px; height: 80px; margin-right: 20px"
              :src="item"
              :preview-src-list="srcList"
            >
            </el-image>
          </p>
          <p v-show="(form.state == 2 && modalState != 2) || form.state == 3">
            <span class="spa">审批意见</span>
            <el-input
              type="text"
              size="mini"
              v-model="form.describe"
            ></el-input>
          </p>
        </div>
        <div class="bottom">
          <button class="btn_red" v-show="modalState == 0" @click="onsubmit">
            保存
          </button>
          <button class="btn_gray" v-show="modalState == 0" @click="modal = 0">
            取消
          </button>
          <button
            class="btn_red"
            v-show="modalState == 4"
            @click="onsubmit1(1)"
          >
            通过
          </button>
          <button
            class="btn_gray"
            v-show="modalState == 4"
            @click="onsubmit1(2)"
          >
            驳回
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  acAdd,
  acExamine,
  acInfo,
  acGetList,
  getMainList,
  axiosUrl,
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: {},
      info: 1,
      axiosUrl,
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      option4: [
        { name: "1%", id: 1 },
        { name: "3%", id: 3 },
        { name: "6%", id: 6 },
        { name: "9%", id: 9 },
        { name: "13%", id: 13 },
      ],
      option2: [{ name: "发票作废", id: 1 }],
      option3: [
        { name: "普通发票", id: 1 },
        { name: "增值税专用发票", id: 2 },
      ],
      modalState: 0,
      option5: [],
      option6: [],
      option8: [],
      option10: [],
      fileList: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ key: 0 }],
      ruleForm: {
        pwd: 123456,
      },
      billForm: {},
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      option1: [
        {
          value: "1",
          label: "已作废",
        },
        {
          value: "2",
          label: "待审核",
        },
        {
          value: "3",
          label: "被驳回",
        },
      ],
      srcList: [],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
    };
  },
  methods: {
    getData() {
      let data = {
        state: this.searchForm.state,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      acGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
     fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach((item) => {
        str += item.response.data.url + ",";
      });
      this.form.file = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach((item) => {
          str += item.response.data + ",";
        });
        this.form.file = str.slice(0, -1);
      }
    },
    //审核
    onsubmit1(i) {
      acExamine({
        id: this.form.id,
        state: i,
        file: this.form.file,
        describe: this.form.describe,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.getData();
          this.modal = 0;
          this.form = {};
          this.fileList = [];
          this.billForm = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    onsubmit() {
      let data = {
        mainType: this.form.mainType,
        mainId: this.form.mainId,
        reason: this.form.reason,
      };
      acAdd(data).then((res) => {
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.modal = 0;
          this.form = {};
          this.fileList=[]
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleCommand(d) {
      this.modalState = d.num;
      if (d.num == 2 || d.num == 4) {
        acInfo({ id: d.data.id }).then((res) => {
          if (res.code == 200) {
            this.form = res.data;
            if (res.data.file) {
              this.srcList = res.data.file.split(",");
            } else {
              this.srcList = [];
            }
            this.billForm = res.data.main;
            this.modal = 1;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if (d.num == 3) {
        this.dialogVisible = true;
      }
    },
    change(i) {
      getMainList({ type: i }).then((res) => {
        if (res.code == 200) {
          this.option5 = res.data;
        } else {
          this.option5 = [];
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
  },
};
</script>

<style lang="less" >
.br {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }

  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .jstitle {
          span {
            display: inline-block;
            margin-right: 12px;
          }
          & > span:nth-child(1) {
            width: 160px;
          }
          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 160px;
            margin-right: 12px;
            .el-input {
              width: 160px;
            }
          }
          .el-input {
            width: 80px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        .btn_red {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>